<template>
  <div class="container mx-auto">
    <div class="text-4xl font-bold mb-10 mx-4">My Lists</div>
    <div class="grid grid-cols-1 xs:grid-cols-2 gap-4">
      <list-button
        class="border-xl mx-2 xs:mx-0"
        v-for="(list,i) in lists"
        :key="i"
        :list="list"
      />
      <div class="new-list-btn mx-2 xs:mx-0" v-ripple @click="toggleShowEditList">
        Create New List <s-icon ico="mdi-plus" size="38"/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex'
import ListButton from "@/views/Lists/ListButton";
import SIcon from "@/components/core/SIcon";
export default {
  name: "Lists",
  components: {SIcon, ListButton},
  computed: {
    ...mapState('lists', ['lists'])
  },
  methods: {
    ...mapMutations(['toggleShowEditList'])
  }
}
</script>

<style scoped lang="scss">
  .new-list-btn{
    @apply flex rounded-xl justify-center text-3xl text-gray-500 font-bold py-10 hover:bg-darken-20 cursor-pointer;
    border: 4px dashed rgba(0,0,0,0.3);
  }
</style>